import "bootstrap/dist/css/bootstrap.min.css";
import React from "react";
import { isProjectRole, isProjectStandardRole, isAdminRole } from "../../redux/actions/userAction";
import ToggleExternalModal from './ToggleExternalModal';
import "./Comments.css";

const IndividualComment = ({ item, projectId }) => {
  return (
    <div className="comment-full-container">
      <div className="comment-image"></div>
      <div className="comment-content">
        <div className="comment-container">
          <div className="comment-name-time">
            {isProjectRole() ? (
              <div className="comment-name">{item.userName} </div>
            ) : (
              <></>
            )}
            <div className="comment-time">{item.timestamp}</div>
          </div>
          <div className="comment-comment">{item.comments}</div>
        </div>
        {isProjectStandardRole() || isAdminRole() ? (
          <ToggleExternalModal item={item} projectId={projectId} />
        ) : null}
      </div>
    </div>
  );
};

export default IndividualComment;
